<template>
  <exhibitorList
    ref="exhibitorList"
    :loading="loading"
    :finished="finished"
    @moreClick="moreClick"
  ></exhibitorList>
</template>

<script>
import exhibitorList from "@components/exhibitorList/exhibitorList.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "exhibitor",
  data() {
    return {
      dataList: [],
      totalCount: 0,
      pageIndex: 1,
      pageSize: 20,
      finished: false,
      loading: false,
    };
  },
  created() {
    this.getExList();
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      user:"getUser"
    }),
  },
  components: { exhibitorList },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
    }),
    //获取数据
    async getExList() {
      this.loading = true;
      let query = `
        query($query:QueryInput!){
          exhibitorFollowerQuery{
            query(query:$query){
              hasNextPage
              pageIndex
              totalCount
              items {
                exhibitor{
                  activities(num:3){
                  id
                  title
                }
                banner
                boothArea
                boothInfo
                businesses(num:3){
                  id
                  remark
                  isSample
                }
                category
                company{
                  id
                  logo
                  name
                  category
                  region
                  address
                }
                compId
                exhibitionId
                followCount
                id
                isPopularize
                isLiving
                likeCount
                lookCount
                proCount
                activityCount
                activityNumber
                productNumber
                liveCount
                continuouNumber
                likes{
                  userInfo
                }
                newestEvaluates{
                  id
                  content
                }
                products(num:10){
                  id
                  exhibitorId
                  banner
                  view3DUrl
                }
                shareCount
                talkCount
                thinkCount
                userCount
                viewCount
                }
              }
            }
          }
        }
      `;
      let where={
        "Equal":{
          "n":"userId",
          "v":this.user.userId
        },
        "EqualB":{
          "n":"exhibitionId",
          "v":this.exhibitionId
        }
      }
      let order=[
        {
          "N":"createAt",
          "v":0
        }
      ]
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.pageIndex,
            size: this.pageSize,
            where:JSON.stringify(where),
            order:JSON.stringify(order)
          },
        },
      };
      let res = await this.graphqlPost(opt);
      let data = JSON.parse(JSON.stringify(res.data.exhibitorFollowerQuery));
      if (data && data.query) {
        let currentList = [];
        data.query.items.forEach(e => {
          currentList.push(e.exhibitor)
        });
        currentList.map((c) => {
          if (c.category && c.category.indexOf("[") !== -1) {
            if (JSON.parse(c.category)[0]) {
              c.category = JSON.parse(c.category)[0].Value;
            } else {
              c.category = "";
            }
          }
          if (c.company.region) {
            let arr = c.company.region.split("/");
            c.regionTxt = arr[arr.length - 1] + "·" + arr[arr.length - 2];
          } else {
            c.regionTxt = "";
          }
        });
        if (this.pageIndex == 1) {
          this.dataList = currentList;
        } else {
          this.dataList = [...this.dataList, ...currentList];
        }
        this.loading = false;
        if (!data.query.hasNextPage) {
          this.finished = true;
        }
      }
      if (this.$refs.exhibitorList) {
        this.$refs.exhibitorList.setData(this.dataList);
      }
    },
    //点击加载更多
    moreClick() {
      if (this.finished) return;
      this.pageIndex += 1;
      this.getExList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.page-c3 {
  padding: 4px 20px 20px 20px;
}
.exhibitor_list {
  .ex {
    display: flex;
    flex-wrap: wrap;
    .ex_item {
      width: 450px;
      height: 136px;
      margin: 0 20px 20px 0;
      background: #fff;
      border: solid 1px #ededed;
      padding: 20px;
      display: flex;
      position: relative;
      overflow: hidden;
      &:nth-child(even) {
        margin-right: 0;
      }
      .item_left {
        width: 96px;
        height: 96px;
        position: relative;
        border-radius: 3px;
        overflow: hidden;
        border: 1px solid #e6e6e6;
        margin-right: 10px;
        img {
          display: block;
          height: 100%;
          width: 100%;
        }
        .is_live {
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 62px;
          height: 20px;
          background-image: linear-gradient(90deg, #ff416c 0%, #ff4b2b 100%),
            linear-gradient(#000, #000);
          color: #fff;
          font-size: 12px;
          padding: 4px 6px;
          span {
            display: block;
            width: 8px;
            height: 8px;
            background: #fff;
            border-radius: 50%;
          }
        }
      }
      .item_right {
        line-height: 1.3;
        max-width: calc(100% - 106px);
        overflow: hidden;
        .rig_top {
          display: flex;
          align-items: center;
          p {
            color: #333;
            font-weight: bold;
            font-size: 16px;
          }
          .continuous_ex {
            margin-left: 10px;
            flex-shrink: 0;
            height: 20px;
            background: rgba(#ff5e00, 0.15);
            display: flex;
            align-items: center;
            color: #ff5e00; 
            font-size: 12px;
            padding: 1px;
            .img_icon {
              width: 18px;
              height: 18px;
              margin-right: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #fff;
              img {
                width: 14px;
                height: 14px;
              }
            }
            span {
              margin-right: 5px;
            }
          }
        }
        .rig_2 {
          margin-top: 6px;
          font-size: 14px;
          color: #333;
          .iconfont {
            font-size: 14px;
            margin-right: 7px;
            &.icon-dizhi {
              color: #fe781d;
            }
            &.icon-biaoqian1 {
              @include font_color(#1890ff);
            }
          }
          .addr {
            margin-right: 19px;
          }
        }
        .rig_3 {
          margin-top: 6px;
          display: flex;
          align-items: center;
          .issue {
            font-size: 12px;
            color: #333;
            span {
              color: #ff5c00;
              &.span2 {
                margin-left: 8px;
              }
            }
          }
          .like_count {
            margin-left: 20px;
            display: flex;
            align-items: center;
            img {
              width: 21px;
              height: 21px;
              border-radius: 50%;
              border: 1px solid #fff;
              z-index: 3;
              position: relative;
              &:nth-child(2) {
                z-index: 2;
                margin-left: -10px;
              }
              &:nth-child(3) {
                z-index: 1;
                margin-left: -10px;
              }
            }
            p {
              color: #666;
              font-size: 12px;
              margin-left: 6px;
            }
          }
        }
        .rig_4 {
          margin-top: 8px;
          color: #666;
          font-size: 12px;
        }
      }
      .hot_rank {
        width: 60px;
        height: 60px;
        background: rgba(#ff5e00, 0.15);
        position: absolute;
        right: -30px;
        top: -30px;
        transform: rotate(45deg);
        span {
          position: absolute;
          bottom: 4px;
          left: 50%;
          font-size: 12px;
          color: #ff5e00;
          transform: translateX(-50%);
          line-height: 1;
          white-space: nowrap;
        }
      }
    }
  }
  .more {
    font-size: 14px;
    color: #999;
    text-align: center;
    cursor: pointer;
    user-select: none;
    margin: 20px 0 70px;
    &:hover {
      @include font_color(#1890ff);
    }
  }
}
</style>
